import React from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from 'common/theme/appModern';
import { ResetCSS } from 'common/assets/css/style';
import BannerSection from 'containers/SassMinimal/BannerSection';

import GlobalStyle, {
  AppWrapper,
  ContentWrapper,
} from 'containers/AppModern/appModern.style';

import SEO from 'components/seo';

export default function () {
  return (
    <ThemeProvider theme={theme}>
      <>
        <SEO title="main" />
        <ResetCSS />
        <GlobalStyle />
        <AppWrapper>
          <ContentWrapper>
               <BannerSection />           
          </ContentWrapper>
        </AppWrapper>
      </>
    </ThemeProvider>
  );
}
